<template>
  <div
    class="blog-grid-area section-space--inner--80 section-space--bottom--60"
  >
    <div class="container">
      <div class="row mx-auto">
        <div class="col-md-12">
          <div class="section-title-area text-center">
            <h1 class="section-title section-space--bottom--50">
              Produk Unggulan
            </h1>
          </div>
        </div>
        <div class="col-lg-12 col-12">
          <div class="service-slider">
            <div class="swiper-container service-slider__container">
              <div class="swiper-wrapper service-slider__wrapper">
                <swiper :options="swiperOption">
                  <div
                    class="swiper-slide"
                    v-for="featureproduct in featureproducts"
                    :key="featureproduct.id_produk"
                  >
                    <router-link
                      :to="
                        /detil-product/ +
                        featureproduct.id_produk +
                        /jenis-produk/ +
                        featureproduct.jenis_produk +
                        /merk-produk/ +
                        featureproduct.merk_produk +
                        /nama-produk/ +
                        featureproduct.nama_produk
                      "
                    >
                      <div class="card">
                        <img
                          class="card-img-top img-unggulan"
                          :src="featureproduct.url_gambar_produk"
                          :alt="featureproduct.nama_produk"
                        />
                        <div class="card-body">
                          <h5 class="card-title">
                            <div v-if="featureproduct.nama_produk.length < 40">
                              <p class="title">
                                {{ featureproduct.nama_produk }}
                              </p>
                            </div>
                            <div v-else>
                              <p class="title">
                                {{
                                  featureproduct.nama_produk.substring(0, 35) +
                                  "..."
                                }}
                              </p>
                            </div>
                          </h5>

                          <p class="p-price">
                            <!-- {{ formatPrice(hargamin.harga_produk) }} -
                          {{ formatPrice(hargamax.harga_produk) }} -->
                            Harga di Detail Produk
                          </p>

                          <p class="p-merk">
                            {{ featureproduct.jenis_produk }}
                            {{ featureproduct.merk_produk }}
                          </p>
                        </div>
                      </div></router-link
                    >
                  </div>
                </swiper>
                <div
                  class="ht-swiper-button-prev ht-swiper-button-prev-13 ht-swiper-button-nav d-none d-xl-block"
                >
                  <i class="fa fa-angle-left" style="font-size: 25px"></i>
                </div>
                <div
                  class="ht-swiper-button-next ht-swiper-button-next-13 ht-swiper-button-nav d-none d-xl-block"
                >
                  <i class="fa fa-angle-right" style="font-size: 25px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="col-12 col-lg-3 col-md-6 mb-4"
          v-for="featureproduct in featureproducts"
          :key="featureproduct.id_produk"
        >
          <router-link
            :to="
              /detil-product/ +
              featureproduct.id_produk +
              /jenis-produk/ +
              featureproduct.jenis_produk +
              /merk-produk/ +
              featureproduct.merk_produk +
              /nama-produk/ +
              featureproduct.nama_produk
            "
          >
            <div class="card">
              <img
                class="card-img-top"
                :src="featureproduct.url_gambar_produk"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title">
                  <div v-if="featureproduct.nama_produk.length < 40">
                    <p class="title">{{ featureproduct.nama_produk }}</p>
                  </div>
                  <div v-else>
                    <p class="title">
                      {{ featureproduct.nama_produk.substring(0, 35) + "..." }}
                    </p>
                  </div>
                </h5>

                <p class="p-price">
           
                  Harga di Detail Produk
                </p>

                <p class="p-merk">
                  {{ featureproduct.jenis_produk }}
                  {{ featureproduct.merk_produk }}
                </p>
              </div>
            </div></router-link
          >
          
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ServiceItem from "@/components/ServiceItem.vue";
import axios from "axios";
export default {
  components: {
    ServiceItem,
  },
  data() {
    return {
      featureproducts: [],
      swiperOption: {
        init: true,
        observer: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        preloadImages: true,
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        draggable: true,
        autoplay: {
          delay: 2000,
        },
        // Responsive breakpoints
        breakpoints: {
          1499: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 2,
          },
        },
        navigation: {
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev",
        },
      },
    };
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getData() {
      axios
        .get("https://admin.putraramayana.com/api/produks/getbystatus")
        .then((response) => {
          this.featureproducts = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss"></style>
