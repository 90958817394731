<template>
  <div class="brand-logo-slider-area section-space--bottom--80">
    <div class="container">
      <div class="title-brand text-center">
        <h1>CV. Putra Ramayana</h1>
        <p>
          CV. Putra Ramayana Merupakan Pusat Grosir Aki, Ban, Oli, Sparepart
          Roda 2/4, dan Power Tools
        </p>
      </div>

      <!-- brand logo slider -->
      <div class="col-lg-12 text-center">
        <div class="row">
          <div
            class="col-4 col-lg-2 col-md-3 brand-section"
            v-for="brand in brands"
            :key="brand.id_brand"
          >
            <div class="section-space--bottom--30">
              <!-- <router-link :to="/promo-details/ + brand.nama_brand"> -->
              <img
                :src="brand.url_gambar_brand"
                class="img-fluid"
                alt="banner-promos"
              />
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      brands: [],
    };
  },
  methods: {
    getData() {
      axios
        .get("https://admin.putraramayana.com/api/brands")
        .then((response) => {
          this.brands = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
