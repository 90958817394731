<template>
  <div class="product-item">
    <div class="product-inner">
      <div class="image">
        <img :src="service.image" class="img-fluid" alt="service thumb" />

        <div class="image-overlay">
          <div class="action-buttons">
            <router-link to="/detil-product">
              <button>Lihat Produk</button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="content-left">
          <p class="title"></p>
          <!-- <h4 class="title"><a href="single-product.html">Fasti 2</a></h4> -->
          <p></p>
          <h5 class="jenis">Jenis: Tubeless</h5>
          <h5 class="tipe">Tipe: Fasti 2</h5>
        </div>

        <div class="content-right">
          <p>Mizzle</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
};
</script>
