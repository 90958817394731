var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-grid-area section-space--inner--80 section-space--bottom--60"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mx-auto"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 col-12"},[_c('div',{staticClass:"service-slider"},[_c('div',{staticClass:"swiper-container service-slider__container"},[_c('div',{staticClass:"swiper-wrapper service-slider__wrapper"},[_c('swiper',{attrs:{"options":_vm.swiperOption}},_vm._l((_vm.featureproducts),function(featureproduct){return _c('div',{key:featureproduct.id_produk,staticClass:"swiper-slide"},[_c('router-link',{attrs:{"to":/detil-product/ +
                      featureproduct.id_produk +
                      /jenis-produk/ +
                      featureproduct.jenis_produk +
                      /merk-produk/ +
                      featureproduct.merk_produk +
                      /nama-produk/ +
                      featureproduct.nama_produk}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top img-unggulan",attrs:{"src":featureproduct.url_gambar_produk,"alt":featureproduct.nama_produk}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[(featureproduct.nama_produk.length < 40)?_c('div',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(featureproduct.nama_produk)+" ")])]):_c('div',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(featureproduct.nama_produk.substring(0, 35) + "...")+" ")])])]),_c('p',{staticClass:"p-price"},[_vm._v(" Harga di Detail Produk ")]),_c('p',{staticClass:"p-merk"},[_vm._v(" "+_vm._s(featureproduct.jenis_produk)+" "+_vm._s(featureproduct.merk_produk)+" ")])])])])],1)}),0),_vm._m(1),_vm._m(2)],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"section-title-area text-center"},[_c('h1',{staticClass:"section-title section-space--bottom--50"},[_vm._v(" Produk Unggulan ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ht-swiper-button-prev ht-swiper-button-prev-13 ht-swiper-button-nav d-none d-xl-block"},[_c('i',{staticClass:"fa fa-angle-left",staticStyle:{"font-size":"25px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ht-swiper-button-next ht-swiper-button-next-13 ht-swiper-button-nav d-none d-xl-block"},[_c('i',{staticClass:"fa fa-angle-right",staticStyle:{"font-size":"25px"}})])}]

export { render, staticRenderFns }