<template>
  <div class="main-container">
    <Header />
    <HeroOne />
    <ProdukUnggulan />
    <BrandSection />
    <CTASection />
    <Footer />
    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <!-- <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top> -->
    <a href="https://wa.me/6285853527441" class="float" target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import HeroOne from "../components/sections/HeroOne";
// import ServiceSlider from "../components/sections/ServiceSlider";
import ProdukUnggulan from "../components/sections/ProdukUnggulan";
import BrandSection from "../components/sections/BrandSection";
import CTASection from "../components/sections/CTASection";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  metaInfo() {
    return {
      title: "Home",
      titleTemplate: "%s - CV Putra Ramayana",
      meta: [
        {
          name: "keywords",
          content:
            "Putra Ramayana, CV. Putra Ramayana, CV Putra Ramayana Solo, Grosir Ban, Grosir Sparepart, Grosir Oli, Grosir Aki, Surakarta, Solo",
        },
        {
          name: "description",
          content:
            "CV Putra Ramayana adalah Pusat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Surakarta",
        },
        {
          property: "og:keyword",
          content:
            "Putra Ramayana, CV. Putra Ramayana, CV Putra Ramayana Solo, Grosir Ban, Grosir Sparepart, Grosir Oli, Grosir Aki, Surakarta, Solo",
        },
        {
          property: "og:description",
          content:
            "CV Putra Ramayana adalah Pusat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Surakarta",
        },
        {
          property: "og:title",
          content: "Home - CV Putra Ramayana",
        },
        {
          property: "og:site_name",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:viewport",
          content: "width=device-width, initial-scale=1",
        },

        // TWITTER META
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:site",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        { name: "twitter:title", content: "Home - CV Putra Ramayana" },
        {
          name: "twitter:description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        // Your twitter handle, if you have one.
        { name: "twitter:creator", content: "@cv_putraramayana" },
        {
          name: "twitter:image:src",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        // Google / Schema.org markup:
        { itemprop: "name", content: "Home - CV Putra Ramayana" },
        {
          itemprop: "description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        {
          itemprop: "image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        { name: "robots", content: "index,follow" },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  components: {
    Header,
    HeroOne,
    // ServiceSlider,
    ProdukUnggulan,
    BrandSection,
    CTASection,
    Footer,
    OffCanvasMobileMenu,
  },
};
</script>
