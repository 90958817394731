<template>
  <div class="section-space--bottom--80">
    <b-carousel
      v-model="slide"
      :interval="4000"
      controls
      indicators
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with image only -->
      <b-carousel-slide
        v-for="bannerhero in bannerheroes"
        :key="bannerhero.id_banner"
        :img-src="bannerhero.url_gambar_banner"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      bannerheroes: [],
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: "fade",
        autoplay: true,
        spaceBetween: 30,
        navigation: {
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev",
        },
      },
    };
  },

  methods: {
    getData() {
      axios
        .get("https://admin.putraramayana.com/api/bannerheroes")
        .then((response) => {
          this.bannerheroes = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">

</style>
